<template>
  <div class="talentDialog">
    <div class="dialog">
      <div class="title">快来申请社区达人啦！</div>
      <div class="body">
        <p>
          社区达人招募计划温情开启，无论你是饱含专业知识的行业翘楚；穿梭在社群间的社交达人；拥有优质资源的热心居民；还是有着奇思妙想的新新人类，我们希望更多热爱生活、富有才能、愿意资源共享的朋友加入我们，携手为春城未来社区居民带去更美好的生活！
          社区达人认证条件（满足其中之一即可）：
        </p>
        <p>
          1. 热心公益，具有一定号召力，带头组织过社区各项文体、志愿服务活动；
        </p>
        <p>
          2.
          社区党总支委员、居委会成员、居监委成员、业委会成员，能为建设美好社区出谋划策；
        </p>
        <p>
          3. 有茶艺、画画、烘焙等各种类型的专业技能，愿意与居民们分享自身技能；
        </p>
        <p>
          4.
          有理发、美容、文体、教育等各类资源，愿意协助社区运营官共同服务居民；
        </p>
        <p>5.社区各社群、共建单位、公益企业等群主或负责人；</p>
      </div>
      <div class="sure" @click="closeTalent">好的</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "talentDialog",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {},
  mounted() {},
  methods: {
    closeTalent() {
      this.$emit("closeTalent");
    },
  },
};
</script>

<style lang="less" scoped>
.talentDialog {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .dialog {
    width: 90%;
    height: 80%;
    background: #fff;
    box-shadow: 0px 0px 40px 12px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .title {
    background: rgba(0, 126, 255, 1);
    height: 120px;
    text-align: center;
    border-radius: 20px 20px 0 0;
    font-size: 30px;
    line-height: 120px;
    color: #fff;
  }

  .body {
    flex: 1;
    padding: 40px 40px;
    color: rgba(0, 126, 255, 1);
    font-size: 30px;
    box-sizing: border-box;
    overflow: scroll;
  }

  .sure {
    width: 80%;
    height: 90px;
    background: rgba(0, 126, 255, 1);
    border-radius: 48px;
    text-align: center;
    color: #fff;
    font-size: 32px;
    line-height: 90px;
    margin: 50px auto;
  }
}
</style>
<style lang="less">
.talentDialog {
}
</style>
